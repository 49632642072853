@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';


html.rtl {
  .home-links {
    text-align: right;
  }
}

.home-links {
  display: flex;
  text-align: left;
  flex-direction: column;

  $h: 60px;

  .home-links-link {
    border-bottom-color: rgba($body-color-dark, 0.12);
    color: $body-color-dark;
    min-height: $h;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    span.text {
      flex: 1;
    }

    span {
      font-weight: 500;
    }
  }
}
