@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
@import './variables';


.feature-box {
  background: $fym-black-gradient;
  border-radius: 12px;
  padding: 30px 20px;
  margin: 10px;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;

  .feature-title {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }
}