@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
@import './variables';

.carousel-full-page {
  width: 100%;
  height: 100%;
  z-index: $zindex-carousel-full-page;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}