@import 'stylekit';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';


.blog-hero-image {
  position: absolute;
  width: 100%;
  height: 70%;
  max-height: 670px;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 30%;

  .blog-hero-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    background: rgba($body-color-light, 0.72);
  }
}

.blog-title {
  position: relative;
  z-index: 2;
  color: $body-color-light;
  font-size: 3rem;
  padding: 0 1rem;
  max-width: 920px;
  text-align: center;
  margin: 2rem auto 1rem auto;
}

.blog-subtitle {
  position: relative;
  z-index: 2;
  color: $body-color-light;
  opacity: 0.64;
  font-size: 1rem;
  padding: 0 1rem 1rem 1rem;
  max-width: 920px;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
}

.blog-post-tags {
  font-family: $font-family-base;
  text-transform: uppercase;
  padding: 1.5rem 2rem 0.5rem 2rem;
  letter-spacing: 2px;
  color: $body-color-light;
  text-align: center;

  a {
    display: inline-block;
    color: $body-color-light;
  }
}

.blog {
  position: relative;

  .blog-page {
    box-shadow: 0 20px 64px 0 rgba(0, 0, 0, 0.24);
    background: $white;
    color: $body-color-light;
    border-radius: 24px;
    padding: 1rem 0;
    @include media-breakpoint-up('md') {
      padding: 2rem 0;
    }
    margin: 3rem auto;

    .blog-featured {
      //box-shadow: 0 20px 64px 0 rgba(0, 0, 0, 0.24);
      box-shadow: 0px 0px 100px -30px rgba(0, 0, 0, 0.24);
      border-radius: 24px;
      margin: -1rem 0 0 0;
      @include media-breakpoint-up('md') {
        margin: -2rem 0 0 0;
      }
    }
    .blog-title {
      text-align: center;
    }
    .blog-entry-list {
      display: flex;

      &.blog-entry-list-wrap {
        flex-wrap: wrap;

        a {
          @include media-breakpoint-up('lg') {
            max-width: 33.33%;
            min-width: 0;
          }
        }
      }

      a {
        display: block;
        color: $body-color-light;

        &:hover {
          color: $body-color-light;
          text-decoration: none;
        }
      }

      .blog-entry-list-item {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up('lg') {
          margin: 1rem;
          align-items: center;
        }

        .blog-entry-list-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          flex: 6;

          .blog-entry-list-title {
            font-family: $font-family-base;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $body-color-light;

            font-weight: bold;
            font-size: 1.2rem;
            opacity: 0.87;

            &:hover {
              opacity: 1;
              color: $fym-primary;
            }
          }

          .blog-entry-list-tags {
            font-family: $font-family-base;
            text-transform: uppercase;
            padding: 0 1rem 0.5rem 0;
            letter-spacing: 2px;
            color: $body-color-light;
            font-size: 0.7rem;
          }

          .blog-entry-list-intro {
            font-family: $fym-font-family-base-for-reading;
            opacity: 0.57;
            font-size: 1.2rem;
            flex: 1;

            img {
              max-width: 100%;
            }
          }

          .blog-entry-list-date {
            opacity: 0.57;
            text-transform: uppercase;
            padding: 0 0 0.5rem 0;
            font-size: 0.7rem;
          }
        }

        .blog-entry-list-promo {
          display: block;
          overflow: hidden;
          max-width: 100%;
          flex: 4;
          border-radius: 10px;

          img {
            display: block;
            overflow: hidden;
            max-width: 100%;
            border-radius: 10px;
          }
        }
      }
    }

    .blog-promo {
      margin: 0 3rem;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }

    .blog-entry {
      .blog-entry-content {
        overflow: hidden;
        font-size: 1.38rem;
        font-family: $fym-font-family-base-for-reading;

        p {
          margin-bottom: 2rem;
        }

        padding: 1.5rem 2rem 0 2rem;
        @include media-breakpoint-up('md') {
          padding: 3rem 6rem 0 6rem;
        }
      }
    }

    .blog-share {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;

      padding: 1.5rem 1rem;
      @include media-breakpoint-up('md') {
        padding: 3rem;
      }

      button {
        margin: 4px 8px;
      }
    }
  }

  .blog-next-title {
    font-size: 3rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    opacity: 0.88;
  }

  .blog-next {
    .blog-next-previous,
    .blog-next-next {
      display: flex;
      flex-direction: row;

      a {
        color: $body-color-light;
        font-weight: bold;
        font-size: 1.2rem;
      }

      span {
        opacity: 0.56;
        text-transform: uppercase;
      }

      img {
        flex-shrink: 0;
      }

      div {
        flex: 1;
      }
    }

    .blog-next-previous {
      text-align: left;
      align-content: start;
    }

    .blog-next-next {
      text-align: right;
      align-content: end;
    }
  }

  .related-blog {
    color: $body-color-light;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    img {
      flex-shrink: 0;
      border-radius: 8px;
    }

    .related-blog-title {
      min-height: 70px;
      text-align: center;
    }
  }

  .blog-pagination {
    display: flex;
    align-content: center;
    align-items: center;

    .blog-pagination-newer,
    .blog-pagination-older {
      color: $body-color-light;

      &:hover {
        text-decoration: none;

        span {
          opacity: 1;
        }
      }

      span {
        opacity: 0.56;
        text-transform: uppercase;
      }

      &.disabled {
        opacity: 0.5;

        &:hover {
          span {
            opacity: 0.56;
          }
        }
      }
    }

    .blog-pagination-info {
      flex: 1;
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 5px;
      text-align: center;

      a {
        color: $body-color-light;
        margin: 0 5px;
        width: 60px;
        display: inline-block;
      }

      .muted {
        opacity: 0.4;
      }
    }
  }
}
