@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit.scss';

.available-music-services-carousel {
  user-select: none;
  margin: auto;

  .available-music-services-carousel-item-container {
    background: $body-bg-dark;
    width: 100%;
    height: 100%;
    padding: 4px;

    .music-logo {
      background: $fym-music-carousel-gradient-dark !important;
    }

    .available-music-services-carousel-item {
      background: $body-bg-dark;
      width: 100%;
      height: 100%;

      transition: opacity 1s;
      opacity: 0.2;

      background: $body-bg-dark;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);

      display: flex;
      flex-direction: column;
      align-content: center;
      border-radius: 8px;

      .available-music-services-carousel-item-bg {
        border-radius: 8px;
        flex: 1;
        margin: 1px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .music-logo {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
        }
      }
    }

    &.isActive {
      .available-music-services-carousel-item {
        opacity: 1;
        background: $fym-gradient;
      }
    }
  }
}

.available-music-services-divider {
  min-width: 38px;
}
