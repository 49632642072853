@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.move-from-to-upselling-bg {
  position: sticky;
  width: 100%;
  background-image: url('/statics/images/move/upselling-bg.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
  top: calc(50% - 425px);
  overflow: hidden;
  z-index: 100;

  @include media-breakpoint-only('xs') {
    padding-top: 200px;
    background-image: url('/statics/images/move/upselling-bg-mobile.jpg');
    background-position: right top;
    background-size: auto;

  }
}

.move-from-to-upselling-container {
  max-width: 1200px;
  margin: auto;
}

.move-from-to-upselling {
  padding: 0 15px;
  margin: 0;
  transition: transform 0.1s;

  &.isHidden {
    margin-top: -850px;
    opacity: 0;
  }

  div {
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;

    span {
      color: rgba(#fff, 0.56);
    }
  }

  p {
    font-weight: 500;
    font-size: 1.4rem;
    color: #fff;
  }
}