@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.statsCommonGrouping {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  .statsCommonGrouping-labels {
    display: flex;
    flex-direction: row;
    margin: 0 -2.5rem 1rem;

    .statsCommonGrouping-element {
      margin: 0 2.5rem;
      font-size: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 300;
      cursor: pointer;
      color: $body-color-dark;

      &:hover {
        text-decoration: none;
      }

      .statsCommonGrouping-element-underline {
        background-color: $body-color-dark;
        height: 6px;
        width: 100%;
        border-radius: 3px;
        display: none;
      }

      &.statsCommonGrouping-element-isSelected {
        font-size: 2.3rem;
        font-weight: 500;

        .statsCommonGrouping-element-underline {
          display: block;
        }
      }
    }
  }

}


