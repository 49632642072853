@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.statsCollectionItem {
  border-bottom-color: mix($body-color-dark, $body-bg-dark, 8%);
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  @include media-breakpoint-down('md') {
    flex-direction: column;
  }

  @mixin small() {
    .statsCollectionItem-image {
      width: 55px;
      height: 55px;
    }

    .statsCollectionItem-content {
      flex-direction: row;
      flex: 1;
      align-items: center;

      .statsCollectionItem-name-main {
        font-size: 1.5rem;
      }

      .statsCollectionItem-name-desc {
        display: none;
      }
    }
  }

  &.statsCollectionItem-small {
    @include small();
  }

  .statsItem-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .statsCollectionItem-image {
    width: 150px;
    height: 150px;
    flex-shrink: 0;

    @include media-breakpoint-down('sm') {
      width: 100px;
      height: 100px;
    }
  }

  .statsCollectionItem-content {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    @include media-breakpoint-down('sm') {
      align-items: center;
      margin-left: 15px;
    }
  }

  .statsCollectionItem-name {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.statsCollectionItem-name-centered {
      justify-content: center;
    }

    .statsCollectionItem-name-main {
      font-size: 2.5rem;
    }

    .statsCollectionItem-name-desc {
      font-size: 1rem;
      opacity: 0.4;
    }
  }

  .statsCollectionItem-boxes {
    margin: 0 -30px;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down('sm') {
      align-items: center;
      margin: 15px -15px 0;
    }
  }
}


