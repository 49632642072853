@use "sass:color";
@use "sass:map";
@import "./stylekit";

$prefix: "bs-dark-";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

// Bootstrap
$border-radius: 0.3rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.25rem;
$link-decoration: none;
$font-family-base: $fym-font-family-base;
$primary: $fym-primary;
$secondary: $fym-secondary;

$navbar-dark-color: rgba($body-color-dark, .8);
$navbar-light-color: rgba($body-color-light, .6);

$primary: $fym-primary;
$secondary: $fym-secondary;
$body-color: $body-color-dark;
$body-bg: $body-bg-dark;

$modal-content-color: $body-color-dark;
$modal-content-bg: $body-bg-dark;
$modal-backdrop-bg: $body-bg-light;
$modal-backdrop-opacity: 0.5;

$card-color: $body-color-light;
//$card-bg: lighten($body-bg-dark, 5%);
//
$card-cap-color: $body-color-light;
//$card-cap-bg: lighten($body-bg-dark, 10%);
//
//$pagination-bg: $card-cap-bg;
$pagination-color: $body-color-light;
//$pagination-border-color: $body-bg-dark;
//
//$pagination-hover-bg: $pagination-bg;
//$pagination-hover-border-color: $pagination-border-color;
//
//$pagination-disabled-bg: $body-bg-dark;
$pagination-disabled-color: $body-color-light;
//$pagination-disabled-border-color: $body-bg-dark;

$input-bg: mix(#ffffff, $body-bg-dark, 6%);
$input-group-addon-bg: $input-bg;
$input-border-color: mix(#ffffff, $body-bg-dark, 6%);
$input-group-addon-border-color: $input-border-color;
$input-placeholder-color: mix(#ffffff, $body-bg-dark, 60%);
$input-color: $body-color-dark;
$input-group-addon-color: $input-placeholder-color;

.btn:disabled.btn-input {
  color: $input-placeholder-color;
}

$custom-colors: (
        'primary': $fym-primary,
        'secondary': $fym-secondary,
        'light': $body-color-dark,
        'dark': $body-bg-dark,
        'body-light': $body-bg-dark,
        'gold': $fym-gold,
        'accent': $fym-accent1,
        'info': lighten($fym-gold, 30%),
        'input': $input-bg
);
$theme-colors: map.merge($theme-colors, $custom-colors);
$breadcrumb-active-color: $body-color-light;

@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/root"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
