@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';


.downloadButtons {
  max-width: 600px;
  margin: auto;
  @include media-breakpoint-down('xs') {
    height: 225px;
  }

  .btn.download-button {
    background-color: #000000;
    color: $body-color-dark;

    &:hover {
      color: $body-color-dark;
      background-color: lighten($body-bg-dark, 5%);
      opacity: 0.8;
    }
  }

  a {
    color: $body-color-dark;
  }

  .alsoOn {
    a {
      padding: 15px 10px;
      margin: 5px 10px;
      min-width: 48px;
    }
  }

  &.forUpselling {
    .col {
      padding: 0 5px;
    }

    .alsoOn {
      a {
        background-color: #262626;
        border-radius: 6px;
        color: #ffffff;
      }
    }
  }

  .btn.download-button {
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 50px;
    padding-top: 0.775rem;
    padding-bottom: 0.775rem;
    white-space: nowrap;
    border-color: #a6a6a6;

    &:hover {
      opacity: 0.8;
    }

    &.macos-download {
      background-position: 16px 36%;
      background-image: url('/statics/images/home/top/download-macos.svg');
    }

    &.windows-download {
      background-position-x: 14px;
      background-image: url('/statics/images/home/top/download-windows.svg');
    }

    &.linux-download {
      background-position-x: 12px;
      background-image: url('/statics/images/home/top/download-linux.svg');
    }

    &.ios-download {
      background-image: url('/statics/images/home/top/download-ios.svg');
    }

    &.android-download {
      background-image: url('/statics/images/home/top/download-android.svg');
    }
  }

  .btn.download-mobile-button {
    width: 140px;
    background-size: 80%;

    @include media-breakpoint-up('lg') {
      width: 160px;
    }
  }

  .download-native-store-image {
    height: 48px;
    margin-bottom: 12px;

    &:hover {
      opacity: 0.8;
    }
  }
}
