@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
@import './variables';

.popular-transfer-element {
  background: $fym-black-gradient;
  border-radius: 12px;
  margin: 10px;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;

  a {
    padding: 30px 20px;
  }
}