@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import 'stylekit';
@import 'functions';
@import 'variables';


.music-services-slider {
  .music-services-slider-element {
    width: 177px;
    height: 120px;
    border-radius: 12px;
  }
}

.music-services {
  .music-services-description {
    font-size: 1.2rem;
  }
}