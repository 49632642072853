@import 'stylekit';
@import 'variables';

$swingDeg: 2deg;

@keyframes swinging{
    0%{transform: rotate($swingDeg);}
    50%{transform: rotate($swingDeg * -1)}
    100%{transform: rotate($swingDeg);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}