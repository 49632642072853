.admin-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .admin-container-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.children-margin-right > * {
  margin-right: 5px !important;
}
