@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';


.animated-tiles {
  overflow-x: hidden;
  max-width: 100%;

  .animated-tiles-element {
    background-image: $fym-black-gradient;
    border-radius: 12px;
    position: relative;
    font-size: 1.5rem;

    .animated-tiles-element-icon-fill {
      svg * {
        fill: url(#fym-radial);
      }
    }
  }
}

.animated-tile {
  opacity: 0;

  &.isVisible {
    opacity: 1;
  }
}
