@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';
@import 'src/assets/styles/stylekit';
@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';


.quotes {
  justify-items: center;
  justify-content: center;

  .quotes-slider-element {
    background: $fym-black-gradient;
    border-radius: 12px;
    padding: 30px 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    max-width: 400px;
    position: relative;

    .quotes-slider-element-content {
      font-size: 1rem;
      flex: 1;
      //@include media-breakpoint-only('xs') {
      //  font-size: 1rem;
      //}
    }

    .quotes-slider-element-message {
      opacity: 0.56;
      font-weight: 300;
    }

    .quotes-slider-element-author {
      opacity: 0.56;
      margin-top: 40px;
    }

    .quotes-slider-element-bottom {
      display: flex;
      flex-direction: row;

      .quotes-slider-element-stars {
        margin: 0 -5px;
        flex: 1;

        .star {
          margin: 5px;

          &.star-full {
            color: $fym-gold;
          }

          &.star-empty {
            opacity: 0.56;
          }
        }
      }

      .quotes-slider-element-store {
        align-self: flex-end;
      }
    }
  }

  .quotes-description {
    font-size: 1.2rem;
  }
}