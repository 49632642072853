@import 'stylekit';
@import 'functions';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';


.footer-waves {
  background-image: url('/statics/images/footer-bg-dark.jpg');
  background-position: 27% top;
  background-size: 1643px 352px;

  @include media-breakpoint-up('md') {
    background-position: left top;
  }

  background-repeat: repeat-x;
  min-height: 150px;
  width: 100%;
}

.footer {
  background-color: #ffffff;
  color: $body-color-light;

  hr {
    border-top-color: rgba($body-color-light, 0.08);
  }
}

.footer-copyrights {
  opacity: 0.4;
  font-size: 0.8rem;
  color: $body-color-light;
}

.social-icon {
  color: $body-color-dark;
  background: $body-bg-dark;
  opacity: 0.4;
  border-radius: 12px;
  min-width: 24px;
  max-width: 48px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  margin: 0 10px;

  @include media-breakpoint-down('sm') {
    width: 36px;
    height: 36px;
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.footer-links {
  white-space: nowrap;

  .footer-link {
    font-size: 0.8rem;

    a {

      color: rgba($body-color-light, 0.56);
    }
  }
}

.footer-main-links {
  white-space: nowrap;

  .footer-main-link {
    font-size: 1.5rem;

    a {

      color: $body-color-light;
    }
  }
}

.simple-footer-logo-container {
  &:hover {
    text-decoration: none;
  }
}
