// Dark
$body-bg-dark: #191919;
$body-color-dark: #ffffff;

// Light
$body-bg-light: #eeeeee;
$body-color-light: #191919;

$fym-gold: #fbd817;
$fym-primary: #6417fb;
$fym-secondary: #de376a;
$fym-accent1: #A428AF;

$fym-gradient: linear-gradient(90deg, #6417fb 0%, #de376a 51%, #fbd817 100%);
$fym-gradient-text: linear-gradient(90deg, #6417fb 0%, #de376a 41%, #fbd817 100%);
$fym-gradient-y: linear-gradient(180deg, #6417fb 0%, #de376a 51%, #fbd817 100%);
$fym-gradient-radial: radial-gradient(50% 50%, #6417fb 50%, #de376a 75%, #fbd817 100%);
$fym-strong-gradient: linear-gradient(180deg, #6c22fb 0%, #350397 100%);
$fym-music-carousel-gradient-dark: linear-gradient(20deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
$fym-black-gradient: linear-gradient(-29deg, rgba(255, 255, 255, 0) 13%, rgba(255, 255, 255, 0.04) 100%);
$fym-black2-gradient: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 5%, rgba(255, 255, 255, 0.04) 100%);
$fym-white-gradient: linear-gradient(-25deg, rgba(255, 255, 255, 0.35) 5%, #ffffff 100%);
$fym-white2-gradient: linear-gradient(0deg, #eeeeee 5%, #ffffff 100%);
$fym-darker-gray-gray-gradient: linear-gradient(270deg, #363636 0%, #494949 100%);

$fym-font-family-base: var(--main-font), 'Arial', sans-serif;
$fym-font-family-base-for-reading: 'PT Serif', serif;