@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.statsTop {
  position: relative;
  background-color: $body-bg-dark;

  .statsTop-background {
    .statsTop-background-inner {
      background-image: url("/statics/images/sharedCollection/shared-playlist-background.jpg");
      background-position: center bottom;
      background-size: cover;
      max-width: 1920px;
      width: 100%;
      height: 100%;
      margin: auto;
    }

    position: absolute;
    opacity: 0.64;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .statsTop-gradient {
    background-image: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, $body-bg-dark 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
  }

  .statsTop-content {
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 100px 0 100px 0;
    color: $body-color-dark;
    @include media-breakpoint-only('xs') {
      padding: 50px 0 50px 0;
    }

    .statsTop-avatar {
      img {
        width: auto;
        height: 112px;
        border-radius: 25%;
        border: 4px solid white;
      }
    }

    h1 {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-only('xs') {
        font-size: 2rem;
      }
    }

    .statsTop-content-subtitle {
      font-weight: normal;
      font-size: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-only('xs') {
        font-size: 1rem;
      }
      margin: 1rem 0 0 0;
    }

    .statsTop-content-subtitle-content-bottom {
      display: flex;
      flex-direction: row;

      @include media-breakpoint-down('sm') {
        flex-direction: column;
        align-items: center;
      }


      .statsTop-content-subtitle-content-bottom-boxes {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin: 0 -30px;
        flex-wrap: wrap;
        align-items: center;
      }

      .fym-button.outline .fym-button-inner {
        padding: 15px 20px;
      }
    }
  }
}

.statsTop-content-subtitle-box {
  margin: 0 30px;

  @include media-breakpoint-down('sm') {
    margin: 0 15px;
  }

  @include media-breakpoint-up('md') {
    &.stats-top-songs-element-80 {
      width: 80px;
    }
    &.stats-top-songs-element-100 {
      width: 100px;
    }
    &.stats-top-songs-element-130 {
      width: 130px;
    }
  }

  .statsTop-content-subtitle-box-title {
    opacity: 0.4;
    text-transform: uppercase;
    font-size: 0.9rem;
    text-align: left;
  }

  .statsTop-content-subtitle-box-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;

    .statsTop-content-subtitle-box-value {
      padding: 5px;
      opacity: 0.72;
      font-size: 1.2rem;

    }

    .statsTop-content-subtitle-box-icon {
      opacity: 0.4;
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }
}


