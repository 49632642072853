@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.move-from-to-questions {
  margin-bottom: 4rem;
}

.move-from-to-questions-element {
  p {
    color: rgba($body-color-dark, 0.4)
  }

  h3, p {
    font-weight: 500;
    font-size: 1rem;
  }

  .move-from-to-questions-element-button {
    padding-top: 0;

    img {
      width: 16px;
    }
  }
}


