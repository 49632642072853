@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
@import './variables';

.fym-button.force-light, .fym-button-force-light {
  &.outline {
    color: $body-color-light;

    &:hover {
      color: $body-color-dark;
    }

    .fym-button-bg {
      background-color: $body-bg-light;
    }
  }
}

.fym-button.force-dark, .fym-button-force-dark {
  &.outline {
    color: $body-color-dark;

    &:hover {
      color: $body-color-dark;
    }

    .fym-button-bg {
      background-color: $body-bg-dark;
    }
  }
}


.fym-button {
  @extend .fym-button-force-dark;

  &.inverse {
    @extend .fym-button-force-light;
  }
}

.fym-button {
  background: $fym-gradient;
  color: $body-color-dark;
  position: relative;
  transition: color 0.2s ease-in-out;

  &.disabled {
    color: $body-color-dark;
  }

  &:hover {
    color: $body-color-dark;
    text-decoration: underline;

    .fym-button-bg {
      bottom: 100%;
    }
  }

  border: 0;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0;

  .fym-button-inner {
    position: relative;
    padding: 10px 20px;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .fym-button-bg {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    border-radius: 0.26rem;
    transition: bottom 0.2s ease-in-out;
  }

  &.outline {
    padding: 1px;
    @include media-breakpoint-only('xs') {
      padding: 10px;
    }
  }

  @include media-breakpoint-only('xs') {
    padding: 10px;
  }
}

.nav-item {
  .fym-button {
    .fym-button-inner {
      padding: 5px 20px;
      border-radius: 0.2rem;
      font-size: 1rem;
    }
  }
}