@import 'stylekit';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.blog-cta-inner {
  font-family: $fym-font-family-base;
  width: 100%;
  background: $fym-gradient;
  text-align: center;
  color: white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
