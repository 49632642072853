@import "stylekit";

$text-shadow: #fff 0 0 4px;
$box-shadow: 0 0 15 13 rgb(0 0 0 / 38%);

a.apple-ad-a {
  &:hover {
    text-decoration: none;
  }
}

.apple-ad-container {
  font-family: $fym-font-family-base;
  text-align: center;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 1rem;
  flex-direction: column;

  background: url('/statics/images/apple-ad-bg.svg');
  background-size: cover;

  h3 {
    font-weight: bold;
    font-size: 1.4rem;
    position: relative;
    color: $body-color-light;
    text-shadow: $text-shadow;
    -webkit-font-smoothing: antialiased;
  }

  .fym-button-container {
    position: relative;
    margin: 2rem 0;

    .fym-button {
      box-shadow: $box-shadow;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    color: $body-color-light;
    text-shadow: $text-shadow;
    -webkit-font-smoothing: antialiased;
  }
}
