@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.steps-wrapper {
  margin-top: 4rem;
}

.steps-box {
  background: $fym-white-gradient;
  border-radius: 8px;
  padding: 4rem 0;

  .step-box {
    max-width: 100%;
    margin: 0;
    padding-right: 1rem;
    @include media-breakpoint-up('sm') {
      max-width: 700px;
      margin-left: 2rem;
    }
  }

  h3 {
    font-size: 2.4rem;
  }

  p {
    text-align: start;
    font-size: 1.4rem;
  }

  .number-of-step {
    svg {
      scale: 0.5;
    }
  }

  .warning-box {
    border: solid 0.1rem #f1f1f1;
    background: $fym-white2-gradient;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;

    .warning-icon {
      padding-right: 0.8rem;
      color: red;

      .fa-duotone-group {
        .fa-primary {
          color: white;
        }
      }
    }
  }
}

.btn-transfer {
  scale: 0.7;
  padding-bottom: 2rem;
}