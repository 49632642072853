@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.stats-option-element {
  a {
    border-radius: 12px;
    display: block;
    padding: 2rem 0;
    color: $body-color-dark;
    background: $fym-black-gradient;

    &:hover {
      color: $body-color-dark;
      background: $fym-black2-gradient;
    }
  }
}


