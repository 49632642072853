@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import './stylekit';
@import './functions';
@import './variables';


.what-is {
  background: $fym-black-gradient;
  border-radius: 12px;
  padding: 60px 40px;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
}