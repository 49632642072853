@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.premium-success-top-subtitle {
  font-size: 1.2rem;
}

.faq-box {
  background: $fym-black-gradient;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 30px;
  margin: 15px 0;

  .faq-box-answer {
    font-size: 1.2rem;
    opacity: 0.56;
  }
}

.premium-pricing {
  .premium-pricing-subtitle {
    font-size: 1.2rem;
  }

  .premium-pricing-switch {
    display: inline-flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    position: relative;

    .fym-switch {
      margin: 0 5px;
    }
  }

  .premium-pricing-switch-label {
    margin: 0 5px;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 24px;

    opacity: 0.4;
    transition: opacity 0.3s;

    &.isActive {
      opacity: 1;
    }
  }

  .premium-pricing-save-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -25%;
    top: -31px;
    opacity: 0.4;
    transition: opacity 0.3s;

    &.isActive {
      opacity: 1;
    }


    .premium-pricing-save-label {
      background-color: $fym-secondary;
      color: $body-color-dark;
      font-size: 0.7rem;
      border-radius: 4px;
      transform: rotate(-15deg);
      transform-origin: bottom center;
      padding: 5px 10px;
    }

    .premium-pricing-save-oval {
      $oval-size: 12px;
      border-left: 2px solid $fym-secondary;
      border-bottom: 2px solid $fym-secondary;
      border-radius: 0 0 0 100%;
      height: $oval-size;
      width: $oval-size;
      margin: 4px 0 0 (($oval-size/2) + 10px);
    }

  }

  .premium-pricing-element {
    text-align: center;
    padding: 2rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &.featured {
      background-image: $fym-black2-gradient;
    }

    .premium-pricing-element-label {
      background-color: rgba($body-color-dark, 0.2);
      padding: 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      opacity: 0;
      height: 40px;
      margin: -2rem -2rem 0;
      font-size: 0.8rem;
      font-weight: 500;

      &.isVisible {
        opacity: 1;
      }
    }

    .premium-pricing-element-title {
      font-size: 1.5rem;
      font-weight: 500;
      padding: 1rem 0;
    }

    .premium-pricing-element-price {
      font-weight: bold;
      font-size: 2.3rem;

      .text-discounted {
        color: $fym-accent1;
        font-size: 1.3rem;
        text-decoration: line-through;
      }
    }

    .premium-pricing-element-subtitle {
      font-size: 0.7rem;
      opacity: 0.56;
      font-weight: normal;
    }

    .premium-pricing-element-list-title {
      text-align: left;
      font-size: 0.7rem;
      opacity: 0.56;
      font-weight: bold;
    }

    .premium-pricing-element-list-container {
      list-style-type: none;
      padding-left: 0;
      margin-left: 1.7em;

      li {
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
        padding: 0 0 20px;

        .coming-soon {
          background-color: rgba($body-color-dark, 0.2);
          color: $body-color-dark;
          padding: 2px 4px;
          text-transform: uppercase;
          border-radius: 4px;
          white-space: nowrap;
        }
      }
    }

    .premium-pricing-element-button {
      background: $fym-gradient;
      color: $body-color-dark;
      border: 0;
      font-weight: bold;
      font-size: 1.2rem;
      margin: 2.6rem 0;
      padding: 0;

      .premium-pricing-element-button-inner {
        padding: 10px 0;
        border-radius: 0.3rem;
      }

      &.isFree {
        color: $body-color-dark;

        .premium-pricing-element-button-inner {
          background-color: $body-bg-dark;
          margin: 1px;
        }
      }
    }
  }
}