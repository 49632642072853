@import 'stylekit';

.fym-switch {
  height: 24px;
  width: 40px;

  background-color: $fym-secondary;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  justify-content: flex-start;

  &.isEnabled .fym-switch-inner {
    transform: translateX(16px);
  }

  .fym-switch-inner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $body-color-light;
    transition: transform 0.3s;
  }
}