@import 'stylekit';
@import 'bootstrap';
@import 'variables';
@import 'promo-bar';
@import 'timer';
@import 'admin';
@import 'appleAd';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../node_modules/slick-carousel/slick/slick';
@import '../../../node_modules/slick-carousel/slick/slick-theme';

@import 'animations';

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px)
  }
}

html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

#__next,
body,
html {
  height: 100%;
  width: 100%;
  //transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

html.rtl body {
  text-align: right;
}


.navbar.mainNavbar {

  @extend .navbar-dark;

  .lang-item {
    color: $body-color-light;
  }

  .dropdown-menu {
    background: $body-bg-dark;

    .nav-link {
      color: $body-color-dark;
    }

    .dropdown-item:hover {
      background: $fym-gradient;
    }
  }
}

.main-header-subtitle {
  strong {
    background-color: $body-bg-light;
    color: $body-color-light;
  }
}

pre {
  color: darken($body-color-dark, 20%);
}

.lead, .powered-by {
  color: mix($body-color-dark, $body-bg-dark, 56%);
}


.mainNavbar {
  @include media-breakpoint-only('xs') {
    .navbar-nav {
      padding: 10px 0;

      .nav-link {
        line-height: 36px;
      }
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-only('xs') {
    padding-top: 10px;
  }
}


.lang-picker-preview {
  img {
    height: 16px;
    border: 1px solid $body-color-light;
  }
}

.lang-picker {
  img {
    height: 12px;
    margin-right: 8px;
    margin-left: 8px;
    border: 1px solid $body-color-light;
  }
}

.navbar.mainNavbar {
  z-index: 2;

  @include media-breakpoint-down('lg') {
    .navbar-brand {
      padding-right: 30px;

      &.isCentered {
        padding-right: 0;
      }
    }
  }

  &.navbar-dark {
    .navbar-toggle-custom {
      color: $body-color-dark;
      transition: all 0.5s ease-in-out;
    }
  }
}

.fym-text-background {
  letter-spacing: 1.1px;
  font-weight: bold;

  background: $fym-gradient-radial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opacity-50 {
  opacity: 0.56;
}

.opacity-80 {
  opacity: 0.80;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
}

.main-header {
  font-size: 2.5rem;
  @include media-breakpoint-up('md') {
    font-size: 3.5rem;
  }
  font-weight: bold;
  line-height: 1.2;
  overflow: hidden;

  .thinner {
    font-weight: 300;
    opacity: 0.56;
  }

  .thinner-regular {
    font-weight: normal;
    opacity: 0.56;
  }
}

.main-header-subtitle {
  font-size: 1.6rem;
  @include media-breakpoint-up('md') {
    font-size: 2.1rem;
  }
  font-weight: normal;
  line-height: 1.2;

  strong {
    padding: 0 5px;
    border-radius: 4px;
  }
}

.secondary-header {
  font-size: 2.5rem;
  @include media-breakpoint-up('md') {
    font-size: 3.5rem;
  }
  font-weight: normal;
  line-height: 1.2;
  overflow: hidden;
}

.smaller-header {
  font-weight: bold;
  font-size: 1.5rem;
  @include media-breakpoint-up('md') {
    font-size: 2.5rem;
  }
  //line-height: 1.2;
  overflow: hidden;
}

.fym-gradient-line {
  background: $fym-gradient;
  height: 3px;
  width: 48px;
  margin: 0.5rem 0;
}

.slick-dots li {
  button:before {
    font-size: 12px;
  }

  &.slick-active button:before {
    font-size: 12px;
  }
}

.made-with {
  color: $body-color-dark;
  opacity: 0.56;
  font-weight: 300;
  text-transform: lowercase;
  margin-bottom: 0;
  font-size: 0.8rem;
  text-decoration: none;
}

.slick-dots li {
  button:before {
    background: $body-bg-light;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.slick-active button:before {
    background: $fym-gradient-y;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.font-weight-medium {
  font-weight: 500;
}

.app-detect {
  display: none;
}

.text-gradient {
  display: inline-block;
  /* Fallback: Set a background color. */
  background-color: $fym-primary;

  /* Create the gradient. */
  background-image: $fym-gradient-text;

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.testimonial {
  font-size: 1.5rem;
  font-weight: 300;
}

.cookie-consent {
  z-index: 2001;
  position: fixed;
  left: 100px;
  right: 100px;
  bottom: 10px;
  @include media-breakpoint-down('md') {
    left: 15px;
    right: 15px;
  }
}

.music-service-logo {
  max-width: 100%;
  height: 60px;
  @include media-breakpoint-only('xs') {
    height: 30px;
  }
}


.coming-soon {
  background-color: rgba($body-color-dark, 0.2);
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.9rem;
}

@import 'home/downloadButtons';
@import 'home/top';
@import 'home/picker-carousel';
@import 'home/home';
@import 'home/how-it-works';
@import 'home/downloads-info';
@import 'home/map';
@import 'home/migration-options';
@import 'home/talking';
@import 'home/blog-posts';
@import 'move/how-to-steps';
@import 'move/steps-boxes';
@import 'move/download';
@import 'move/upselling';
@import 'move/questions';
@import 'move/ratings';
@import 'premium/pricing';
@import 'carousel-full-page';
@import 'fym-button';
@import 'mobile-screenshots';
@import 'switch';
@import 'footer';
@import 'graphcms';
@import 'blog';
@import 'blog-cta';
@import 'blog-cta-sml';
@import 'blog-music-streaming-calculator';
@import 'video';
@import 'animatedWave';
@import 'animatedTile';
@import 'features';
@import 'popular-transfers';
@import 'quotes';
@import 'music-services-slider';
@import 'stats/top';
@import 'stats/playlistItem';
@import 'stats/statsCommonGrouping';
@import 'stats/item';
@import 'stats/stats-options';
@import 'stats/list';
@import 'stats/list';
@import 'stats/socialShareModal';
@import 'how-to-page';
@import 'what-is';

