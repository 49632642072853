@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.statsList {
  table, tbody {
    cursor: pointer;
    border-radius: 10px;

    tr:first-child {
      td {
        border: 0;
      }
    }
  }

  .statsList-accountName {
    line-height: 44px;

    a {
      color: $body-color-dark;
    }
  }

  .statsList-msLogo {
    img {
      height: 44px;
    }

    padding-right: 10px;
    padding-left: 10px;
  }

  .statsList-avatar {
    padding-right: 10px;
    padding-left: 10px;

    img {
      width: 44px;
      height: 44px;
      border-radius: 22px;
    }
  }
}


