@import 'stylekit';
@import 'variables';

.timer-container {
  display: flex;

  .timer-container-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: $font-family-monospace;

    .timer-container-value {
      padding: 2px;
      background: lighten($fym-primary, 10%);
      width: 40px;
      margin: 0 4px;
      border-radius: 4px;
    }

    .timer-container-label {
      font-size: 0.5rem;
    }
  }
}