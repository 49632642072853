@import '../stylekit';
@import '../functions';
@import '../variables';

.social-share-modal-container {
  .copy-input {
    position: relative;

    input {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .copy-btn {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      .btn {
        border-radius: 16px;
        margin-right: 10px;
      }
    }
  }
}
