@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.index-blog-posts {
  justify-items: center;
  justify-content: center;

  .index-blog-post-list-item {
    background: $fym-black-gradient;
    color: $body-color-dark;
    border-radius: 12px;
    padding: 30px 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    max-width: 400px;
    position: relative;
    cursor: pointer;


    .index-blog-post-list-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .index-blog-post-list-title {
        font-weight: bold;
        font-size: 1.3rem;
      }

      .index-blog-post-list-intro {
        flex: 1;
      }

      .index-blog-post-list-tags {
        font-family: $font-family-base;
        text-transform: uppercase;
        padding: 0 1rem 0.5rem 0;
        letter-spacing: 2px;
        color: rgba($body-color-dark, 0.8);
      }

      .index-blog-post-list-date {
        opacity: 0.57;
        text-transform: uppercase;
        padding: 3rem 0 0 0;
        font-size: 1.13rem;
      }
    }
  }
}