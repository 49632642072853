@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.available-platforms {
  background: $fym-darker-gray-gray-gradient;
  text-align: center;

  li {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    margin: 5px 10px;
  }
}