@import '../stylekit';

.talking-about-us {
  h3 {
    color: rgba($body-color-dark, 0.56);
    font-size: 1rem;
    letter-spacing: 0;
    text-align: center;
  }

  .talking-about-us-slider-element {
    background: $fym-black-gradient;
    margin: 0 15px;
    height: 140px;
    border-radius: 8px;
  }
}
