@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';


.ratings-box {
  background-image: $fym-black-gradient;
  border-radius: 8px;
  margin: 1rem 2rem;
  width: 100%;
  @include media-breakpoint-up('sm') {
    width: auto;
  }

  div {
    font-size: 2rem;
  }

}

.ratings-icon {
  color: $fym-gold;
  font-size: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-up('md') {
    font-size: 0.2rem;
  }
}


