@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.index-how-it-works-overlay {
  background: $body-bg-dark;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  $ctaHeight: 240px;
  padding-top: 20px;
  padding-bottom: 20px;

  .index-how-it-works-overlay-container {
    max-width: 1100px;
    align-self: center;
    @include media-breakpoint-down('sm') {
      height: 100%;
    }
  }

  h2 {
    flex-shrink: 0;
    font-size: 2.3rem;
    @include media-breakpoint-down('sm') {
      font-size: 2.0rem;
    }
    @include media-breakpoint-only('xs') {
      font-size: 1.5rem;
    }
  }

  position: relative;

  ol {
    text-align: right;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 1.2rem;
      font-weight: bold;
      padding: 16px 0;

      .marker {
        padding-right: 16px;
      }
    }
  }

  .hands-iphone {
    margin-bottom: -$ctaHeight/2;
    margin-left: -10%;
  }
}

.index-how-it-works-list-container {
  flex: 1;
  padding-right: 30px;

  @include media-breakpoint-down('sm') {
    padding-right: 0;
  }
}


.premium-big-cta-container {
  .premium-big-cta {
    cursor: pointer;
    background-image: url('/statics/images/home/premium-big-cta.svg');
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 12px;
    padding: 80px 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    background-position: center center;
    @include media-breakpoint-down('sm') {
      background-image: url('/statics/images/home/premium-big-cta-mobile.svg');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 12px;
      width: 342px;
      height: 142px;
      margin: auto;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    color: white;
    z-index: $zindex-hand-phone + 1;

    .img-fluid {
      margin-bottom: -6%;
      margin-top: -6%;
    }

    .premium-big-cta-text,
    .premium-big-cta-price,
    .premium-big-cta-checkthis {
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      z-index: $zindex-carousel-full-page;
    }

    .premium-big-cta-text {
      top: 18%;

      @include media-breakpoint-up('lg') {
        top: 20%;
      }
    }

    .premium-big-cta-price {
      bottom: 25%;

      @include media-breakpoint-up('md') {
        bottom: 13%;
      }
      @include media-breakpoint-up('lg') {
        bottom: 20%;
      }
      font-size: 3.1rem;
    }

    .premium-big-cta-checkthis {
      bottom: 10%;
      right: 10%;
      left: auto;
      font-size: 1rem;
    }
  }
}

.index-how-it-works-mobile-list {
  background: $body-bg-dark;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  z-index: $zindex-carousel-full-page + 3;

  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: transform 0.3s;
    text-align: center;
    display: flex;

    li {
      font-size: 1.2rem;
      font-weight: bold;
      padding: 16px 0;
      float: left;
      width: 100%;
      flex-shrink: 0;

      .marker {
        padding-right: 16px;
      }
    }
  }
}