@import '../stylekit';

html.rtl {
  .migration-option-element {
    h3 {
      text-align: right;
    }
  }
}

.migration-options-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-4%);

  .migration-option-element-links {
    a {
      margin: 20px 0 40px 0;
    }
  }
}

.migration-option-element {
  h3 {
    text-align: left;
  }
}

.migration-option-element-links {
  li {
    font-size: 12px;
    color: $body-color-dark;
    letter-spacing: 0;
    display: flex;

    a {
      color: $body-color-dark;
      padding: 6px 0;
    }
  }
}
