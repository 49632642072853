@import 'functions';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.mobile-screenshots {
  position: relative;
  display: flex;
  overflow: hidden;
  flex: 1;

  @include media-breakpoint-up('md') {
    flex-shrink: 0;
  }

  .mobile-screenshots-screenshot,
  .mobile-screenshots-screenshot > * {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include media-breakpoint-up('md') {
      top: 20px;
      left: 20px;
      bottom: 20px;
      right: 20px;
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .mobile-screenshots-screenshot {
    z-index: $zindex-mobile-screenshots;
  }
}
