@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

html.rtl {
  .map {
    .map-elements {
      margin-right: 50%;
    }
  }
}

.map {
  background-image: url('/statics/images/map-dark.svg');
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 488px;
  background-size: auto 483px;

  position: relative;
  overflow: hidden;

  .map-elements {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: 50%;

    .map-element {
      position: absolute;
      background: url('/statics/images/map-element.svg');
      padding: 7px 15px;
      height: 48px;
      min-width: 88px;
      text-align: center;
      font-size: 14px;
      color: $body-color-dark;
      letter-spacing: 0;
      line-height: 24px;
      background-size: contain;

      opacity: 0;
      transition: opacity 1s ease-in-out;

      &.multi {
        font-size: 10px;
        line-height: 12px;
      }

      &.isVisible {
        opacity: 1;
      }
    }
  }
}
