@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../stylekit';
@import '../functions';
@import '../variables';

.how-to-container {
  max-width: 1100px;
}

.how-to-steps-inner {
  margin-left: -74px;
}

.how-to-step {
  padding-left: 74px;

  @include media-breakpoint-only('xs') {
    padding-left: 10px;
    svg {
      display: none;
    }
  }
  height: 380px;

  h3 {
    font-size: 2rem;
    font-weight: 500;
  }

  p {
    opacity: 0.48;
    font-size: 1.5rem;
    height: 120px;
    font-weight: 300;

    @include media-breakpoint-down('sm') {
      height: 220px;
    }

    @include media-breakpoint-only('xs') {
      height: auto;
    }
  }

  svg {
    margin-left: -74px;
    margin-bottom: -73px;
  }
}

.how-to-counter {
  margin: 20px -15px 0;

  .how-to-counter-element {
    background-color: rgba($body-color-dark, 0.12);
    width: 24px;
    height: 3px;
    border-radius: 1.5px;
    margin: 0 15px;
    transition: all 1s;

    &.isActive {
      background: $fym-gradient;
      transform: scaleX(2);
    }
  }
}

.images-tel-box {
  overflow: hidden;

  .image-tel-box {
    margin: 0 -1.2rem;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up('lg') {
      margin: 0 -4.6rem;
      img {
        scale: 0.9;
      }
    }
  }

  .image-tel-box-bigger {
    z-index: 99;
  }
}
