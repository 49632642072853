@import 'stylekit';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.blog-cta-sml {
  font-family: $fym-font-family-base;
  height: 300px;
  width: 100%;
  background: $fym-strong-gradient;
  text-align: center;
  color: white;
  border-radius: 10px;

  svg {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.6));
  }

  .button {
    box-shadow: $box-shadow;
  }
}
